import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider
} from '@xyflow/react';
 
import '@xyflow/react/dist/style.css';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import Dependencia from './Dependencia';
import Pie from "../../Pie/Pie";

// Actions
import { getOrganigrama } from '../../../redux/action-types/dependenciaActions';
import { CircularProgress } from '@mui/material';

const initialNodes = [];
const initialEdges = [];

const nodeTypes = ({ dependencia: Dependencia });


const Organigrama = () => {
  const dispatch = useDispatch();

  const { nodos, conectores } = useSelector(state => state.dependencia);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onInit = (rf) => {
    setReactFlowInstance(rf);
  };

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  useEffect(() => {
    dispatch(getOrganigrama());
  }, [dispatch]);

  useEffect(() => {
    if (nodos && conectores) { 
      setNodes(nodos);
      setEdges(conectores);
    }
  }, [nodos, conectores]);

 
  if (!edges || !nodes){
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Organigrama'} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  }

  return (
    <>
    <Rotulo titulo={"Organigrama"}></Rotulo>
    <div style={{ width: '98vw', height: '73vh' }}>
      <ReactFlowProvider>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
        onInit={onInit}
      >
        <Controls />
        <MiniMap 
          style={{ width: 300, height: 150 }}
          draggable
          zoomable 
          zoomStep={5} 
          pannable 
          nodeColor={"primary.light"}
          maskColor='rgba(225, 225, 220, 0.7)'
          maskStrokeColor='black'
          />
        <Background gap={12} size={1} />
      </ReactFlow>
      </ReactFlowProvider>
    </div>
    <Pie />
    </>
  );
}

export default Organigrama;