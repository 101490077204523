import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from "../Rotulo/Rotulo";
import ModalComponent from "../ModalComponent/ModalComponent";
import Pie from "../Pie/Pie";
import Encabezado from "./Components/Encabezado";
import PaginationWithDynamicDots from "./Components/TablePaginationActions";
// Actions
import { buscar_por_id } from "../../redux/action-types/dependenciaActions";
import { marcaciones_por_dependencia, restaurarDependencias, restaurarMensajes } from "../../redux/action-types/marcacionesActions";

// Funciones de ayuda
import { obtenerDia } from "../../utils/obtenerDia";
import { obtenerFecha } from "../../utils/obtenerFecha";
import { obtenerHorario } from "../../utils/obtenerHorario";

// MUI componentes
import {
  Box,
  CircularProgress,
  Chip,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

// MUI iconos
import LoupeIcon from "@mui/icons-material/Loupe";

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';
import { limpiar_marcaciones } from "../../redux/reducers/marcacionesReducer";

// Estado inicial de busqueda
let fechaActual = new Date();
let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 7;
let resta = fechaActual.getTime() - semanaEnMilisegundos;
let fechaInicio = obtenerFecha(new Date(resta)).fecha2;
let fechaFin = obtenerFecha(new Date(fechaActual)).fecha2;

const initialState = {
  inicio: fechaInicio,
  fin: fechaFin,
};

const initialUser = {
  usuario: "",
  nombre: "",
  horario: "",
  sereno: ""
};

const ReporteGeneral = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ isProcessing, setIsProcessing ] = useState(false);
  const [ rowsPerPage, setRowsPerPage ] = useState(10);
  const [ page, setPage ] = useState(0);
  const [ page2, setPage2 ] = useState(1);

  const matches = useMediaQuery("(min-width:650px)");

  const { id, modo } = useParams();
  const { dependencia } = useSelector((state) => state.dependencia);
  const { marcaciones_dependencia, resumen, status, total } = useSelector((state) => state.marcaciones);

  const dniActual = window.localStorage.getItem("dni");
  const permisos = window.localStorage.getItem("permisos");
  const tipo_rol = window.localStorage.getItem("rol");

  const [ fecha, setFecha ] = useState(initialState);
  const [ usuario, setUsuario ] = useState(initialUser);
  const [ datosObtenidos, setDatosObtenidos ] = useState(false);

  const [isOpenModal, closeModal] = useModal();

  const [tab, setTab] = useState("1");

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFecha({
      ...fecha,
      [name]: value,
    });
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setDatosObtenidos(false);
    setPage(newPage);    
    setPage2(newPage + 1);
    const data = {
      id: id,
      dniActual: dniActual,
      inicio: fecha.inicio,
      fin: fecha.fin,
      page: newPage + 1,
      per_page: rowsPerPage,
      con_hijos: modo==="completo" ? true : false
    };
    dispatch(marcaciones_por_dependencia(data)).then(
      setDatosObtenidos(true)
    );
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPage2(1);
    const data = {
      id: id,
      dniActual: dniActual,
      inicio: fecha.inicio,
      fin: fecha.fin,
      page: 1,
      per_page: parseInt(event.target.value, 10),
      con_hijos: modo==="completo" ? true : false
    };
    dispatch(marcaciones_por_dependencia(data));
  };

  const handleClick = () => {
    dispatch(restaurarDependencias());
    navigate(window.sessionStorage.getItem("urlAnterior"));
  };

  const handleClickCreador = (creador) => {
    window.sessionStorage.setItem("urlAnterior", `/reportes-generales/${id}`);
    navigate(`/perfil/${creador}`);
  };

  const handleDetail = (dni, nombre, horario, sereno) => {
    setUsuario({ usuario: dni, nombre: nombre, horario: horario, sereno:sereno });
    setTab("2");
  };


  const handleDetailJustificacion = (dni, justificacion_id) => {
    window.sessionStorage.setItem("urlAnterior", `/reportes-generales/${id}/${modo}`);
    navigate(`/detalle-justificacion/${dni}/${justificacion_id}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDatosObtenidos(false);
    dispatch(restaurarMensajes());
    dispatch(restaurarDependencias());
    const data = {
      id: id,
      dniActual: dniActual,
      inicio: fecha.inicio,
      fin: fecha.fin,
      page: page2,
      per_page: rowsPerPage,
      con_hijos: modo==="completo" ? true : false
    };
    dispatch(marcaciones_por_dependencia(data));
    setDatosObtenidos(true);
  };

  useEffect(() => {
    window.sessionStorage.setItem('urlAnterior', `/reportes-generales`);
    dispatch(limpiar_marcaciones());
    dispatch(buscar_por_id(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(restaurarMensajes());
    dispatch(restaurarDependencias());
    setFecha({ inicio: initialState.inicio, fin: initialState.fin });
    setTab("1");
    const data = {
      id: id,
      dniActual: dniActual,
      inicio: initialState.inicio,
      fin: initialState.fin,
      page: 1,
      per_page: 10,
      con_hijos: modo==="completo" ? true : false
    };
    dispatch(marcaciones_por_dependencia(data));
    setDatosObtenidos(true);
    
    
  }, [dispatch, id, tipo_rol, dniActual, modo]);

  if (!datosObtenidos && dependencia) {
    return (
      <>
        {/* Componente para la navegacion */}
        <Rotulo titulo={"Reporte General"} />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3vh",
          }}
        >
          <Encabezado 
          dependencia={dependencia}
          fecha={fecha}
          handleChange={handleChange}
          handleClick={handleClick}
          handleSubmit={handleSubmit}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          con_hijos= {modo==="completo" ? true : false}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CircularProgress
              sx={{
                width: "auto",
                height: "auto",
                top: "50%",
                left: "50%",
              }}
            />
          </Box>
        </Grid>
        {/* Pie de Pág. */}
        <Pie />
      </>
    );
  };

  if (dependencia && datosObtenidos && !status) {
    return (
      <>
        {/* Componente para la navegacion */}
        <Rotulo titulo={"Reporte General"} />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3vh",
          }}
        >
          <Encabezado 
          dependencia={dependencia}
          fecha={fecha}
          handleChange={handleChange}
          handleClick={handleClick}
          handleSubmit={handleSubmit}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CircularProgress
              sx={{
                width: "auto",
                height: "auto",
                top: "50%",
                left: "50%",
              }}
            />
          </Box>
        </Grid>
        {/* Pie de Pág. */}
        <Pie />
      </>
    );
  };

  if (dependencia && datosObtenidos && (!resumen || resumen?.length < 1)) {
    return (
      <>
        {/* Componente para la navegacion */}
        <Rotulo titulo={"Reporte General"} />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3vh",
          }}
        >
          <Encabezado 
          dependencia={dependencia}
          fecha={fecha}
          handleChange={handleChange}
          handleClick={handleClick}
          handleSubmit={handleSubmit}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Typography variant="h5">
              No hay datos para esta dependencia
            </Typography>
          </Box>
        </Grid>
        {/* Pie de Pág. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      <Rotulo titulo={"Reporte General"} />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <Encabezado 
          dependencia={dependencia}
          fecha={fecha}
          handleChange={handleChange}
          handleClick={handleClick}
          handleSubmit={handleSubmit}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          con_hijos={modo === "completo" ? true : false}
          />

        {/* Componente con las pestañas */}
        <Grid
          item
          xs={12}
          sm={11}
          md={10}
          lg={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid item container>
            <TabContext value={tab}>
              <Grid
                item
                container
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <TabList onChange={handleTab} aria-label="tabs">
                  <Tab
                    label="Reporte"
                    value={"1"}
                    sx={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                      color: "text.default",
                    }}
                  />
                  {tab === "1" ? (
                    <Tab
                      label="Detalle"
                      value={"2"}
                      sx={{ visibility: "hidden", height: 0, width: 0 }}
                    />
                  ) : (
                    <Tab
                      label="Detalle"
                      value={"2"}
                      sx={{
                        fontSize: ".8em",
                        fontWeight: "bold",
                        color: "text.default",
                      }}
                    />
                  )}
                </TabList>
              </Grid>
            </TabContext>
          </Grid>
          {tab === "1" ? (
            <>
              {total ? 
              <>
                {matches && marcaciones_dependencia && (
                <Grid item container>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "1px 1px 3px 3px rgba(40,40,40,0.4)" }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow key={"0"} sx={{ bgcolor: "primary.light" }}>                          
                          <TableCell key={"name"} align="center">
                            Nombre
                          </TableCell>
                          <TableCell key={"days"} align="center">
                            Dias Laborables
                          </TableCell>
                          <TableCell key={"worked_days"} align="center">
                            Dias Trabajados
                          </TableCell>
                          <TableCell key={"justif"} align="center">
                            Faltas justificadas
                          </TableCell>
                          <TableCell key={"injustif"} align="center">
                            Faltas injustificadas
                          </TableCell>
                          <TableCell key={"hours"} align="center">
                            Horas Trabajadas
                          </TableCell>
                          <TableCell key={"justify"} align="center">
                            Detalles
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {
                        <TableBody>
                          {resumen.map((detalle) => {
                            return (
                              <TableRow
                                key={`${detalle.nombre}`}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >                                
                                <TableCell
                                  component="th"
                                  id={detalle.nombre}
                                  scope="row"
                                  padding="normal"
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer",
                                      borderRadius: ".5em",
                                      boxShadow:
                                        "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                    },
                                  }}
                                  onClick={() => handleClickCreador(detalle.dni)}
                                >{detalle.nombre}</TableCell>
                                <TableCell key={"dias_habiles"} align="center">
                                  {detalle.dias_habiles}
                                </TableCell>
                                <TableCell key={"dias_trabajados"} align="center">
                                  {detalle.dias_trabajados}
                                </TableCell>
                                <TableCell key={"justificadas"} align="center">
                                  {detalle.faltas_justificadas === "00:00"
                                    ? "-"
                                    : detalle.faltas_justificadas}
                                </TableCell>
                                <TableCell
                                  key={"injustificadas"}
                                  align="center"
                                >
                                  {detalle.faltas_injustificadas === "00:00"
                                    ? "-"
                                    : detalle.faltas_injustificadas}
                                </TableCell>
                                <TableCell key={"horas"} align="center">
                                  {detalle.horas_trabajadas === "00:00"
                                    ? "-"
                                    : detalle.horas_trabajadas}
                                </TableCell>
                                <TableCell
                                  key={"detalles"}
                                  align="center"
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer",
                                      borderRadius: ".5em",
                                      boxShadow:
                                        "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                    },
                                  }}
                                  onClick={() =>
                                    handleDetail(
                                      detalle.dni,
                                      detalle.nombre,
                                      detalle.horario,
                                      detalle.sereno
                                    )
                                  }
                                >
                                  <LoupeIcon color="primary" />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, total]}
                    count={total}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={"Usuarios por pagina"}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={() => (
                      <div style={{ display: "flex", justifyContent: "space-evenly" }}> {/* Ajustar la distribución horizontal */}
                        <PaginationWithDynamicDots
                          count={total}
                          page={page}
                          rowsPerPage={rowsPerPage}
                          onPageChange={handleChangePage}
                        />
                      </div>
                    )}
                  />
                  
                </Grid>
              )} </>: 
              <CircularProgress></CircularProgress>}
              {!matches && marcaciones_dependencia && (
                <Grid item xs={12} marginLeft={".8em"} marginRight={".8em"}>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "1px 1px 3px 3px rgba(40,40,40,0.4)" }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow key={"0"} sx={{ bgcolor: "primary.light" }}>
                          <TableCell key={"name"} align="center">
                            Nombre
                          </TableCell>
                          <TableCell key={"days"} align="center">
                            Dias Laborables
                          </TableCell>
                          <TableCell key={"worked_days"} align="center">
                            Dias Trabajados
                          </TableCell>
                          <TableCell key={"hours"} align="center">
                            Horas Trabajadas
                          </TableCell>
                          <TableCell key={"justify"} align="center">
                            Detalles
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {
                        <TableBody>
                          {resumen &&
                            resumen.map((detalle) => {
                              return (
                                <TableRow
                                  key={detalle.nombre}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell key={"nombre"} align="left">
                                    {detalle.nombre}
                                  </TableCell>
                                  <TableCell key={"dias"} align="center">
                                    {detalle.dias_habiles}
                                  </TableCell>
                                  <TableCell key={"dias"} align="center">
                                    {detalle.dias_trabajados}
                                  </TableCell>
                                  <TableCell key={"horas"} align="center">
                                    {detalle.horas_trabajadas === "00:00"
                                      ? "-"
                                      : detalle.horas_trabajadas}
                                  </TableCell>
                                  <TableCell
                                    key={"detalles"}
                                    align="center"
                                    sx={{
                                      "&:hover": {
                                        cursor: "pointer",
                                        borderRadius: ".5em",
                                        boxShadow:
                                          "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                      },
                                    }}
                                    onClick={() =>
                                      handleDetail(
                                        detalle.dni,
                                        detalle.nombre,
                                        detalle.horario,
                                        detalle.sereno                                       
                                      )
                                    }
                                  >
                                    <LoupeIcon color="primary" />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12} marginLeft={".8em"} marginRight={".8em"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    ml: "-3px",
                  }}
                >
                  <Typography
                    component={"h3"}
                    variant={"h6"}
                    fontSize={"1.4em"}
                    ml={"2px"}
                  >
                    Marcaciones de {usuario.nombre}
                  </Typography>
                </Box>
              {marcaciones_dependencia[usuario.usuario] ?
                <TableContainer
                component={Paper}
                style={{ boxShadow: "1px 1px 3px 3px rgba(40,40,40,0.4)" }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow
                      key={"detalle_persona"}
                      sx={{ bgcolor: "primary.light" }}
                    >
                      <TableCell key={"day"} width={"5%"}>
                        Día
                      </TableCell>
                      <TableCell key={"date"} width={"5%"}>
                        Fecha
                      </TableCell>
                      <TableCell key={"schu"} align="center" width={"13%"}>
                        Horario
                      </TableCell>
                      <TableCell key={"in"} width={"8%"}>
                        Entrada
                      </TableCell>
                      <TableCell key={"out"} width={"8%"}>
                        Salida
                      </TableCell>
                      <TableCell key={"hours"} width={"8%"}>
                        Horas
                      </TableCell>
                      <TableCell key={"justify"} align="center" width={"40%"}>
                        Justificaciones
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    <TableBody>
                      {marcaciones_dependencia[usuario.usuario] &&
                          marcaciones_dependencia[usuario.usuario].map(
                          (marcacion) => {
                            const dia = obtenerDia(marcacion.fecha_marcacion);
                            return (
                              <TableRow
                                key={marcacion.fecha_marcacion}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell key={"dia"} align="left">
                                  {dia}
                                </TableCell>
                                <TableCell key={"fecha"} align="left">
                                  {marcacion.fecha_marcacion}
                                </TableCell>
                                <TableCell key={"horario"} align="center">
                                  {obtenerHorario(dia, usuario.horario)}
                                </TableCell>
                                <TableCell key={"entrada"} align="left">
                                  {usuario.sereno?
                                    marcacion.entrada === "15:00"
                                    ? "-"
                                    : marcacion.entrada
                                  :
                                  marcacion.entrada === "00:00"
                                    ? "-"
                                    : marcacion.entrada}
                                </TableCell>
                                <TableCell key={"salida"} align="left">
                                  {usuario.sereno?
                                    marcacion.salida === "15:00"
                                    ? "-"
                                    : marcacion.salida
                                  :
                                  marcacion.salida === "00:00"
                                    ? "-"
                                    : marcacion.salida}
                                </TableCell>
                                <TableCell key={"horas"} align="left">
                                  {marcacion.horas_trabajadas === "00:00"
                                    ? null
                                    : marcacion.horas_trabajadas}
                                </TableCell>
                                {marcacion.justificacion.length >= 1 ? (
                                  <TableCell key={"justi"} align="center">
                                    {marcacion.justificacion.map((justificacion) => {                                      
                                        return (
                                          justificacion.eliminada?
                                          verificarPermiso(permisos, 'super_usuario') ?
                                            <Chip sx={{bgcolor: justificacion.general ? 'auditory.main' : "auditory.fourth", color:"white", margin: '3px'}}
                                            key={justificacion.id}
                                            label={justificacion.tipo_justificacion}
                                            onClick={() => handleDetailJustificacion(justificacion.id)} />
                                            :
                                            null
                                          :
                                          <Chip
                                            sx={{
                                                    bgcolor: justificacion.general ?
                                                      "primary.light" : "primary.chip",
                                                    margin: "3px",
                                                    color: "white"
                                            }}
                                            key={justificacion.id}
                                            label={justificacion.tipo_justificacion}
                                            onClick={() =>
                                              handleDetailJustificacion(
                                                marcacion.detalle[0]
                                                  .dni_usuario,
                                                justificacion.id
                                              )
                                            }
                                          />
                                        );
                                      }
                                    )}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={"justi"}
                                    align="left"
                                  ></TableCell>
                                )}
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                  }
                </Table>
              </TableContainer> 
                :
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress
                    sx={{
                      width: "auto",
                      height: "auto",
                      top: "50%",
                      left: "50%",
                    }}
                  />
                </Box>
                }
                
              </Grid>
            </>
          )}
        </Grid>

        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal}>
          <Typography
            id="modal-modal-title"
            component="h2"
            sx={{ fontSize: "1.3em" }}
          >
            Ingrese una fecha de inicio y una de fin para el reporte.
          </Typography>
        </ModalComponent>
      </Grid>
      {/* Componente de Pie Pag */}
      <Pie />
    </>
  );
};

export default ReporteGeneral;
