import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SearchIcon from '@mui/icons-material/Search';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReportIcon from '@mui/icons-material/Report';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import SchemaIcon from '@mui/icons-material/Schema';

const pages = ['Horarios', 'Reportes', 'Justificaciones', 'Relojes', 'Admin'];

const reportesOpciones = [
    {name:'Reportes de Personal', sidebar:"Reportes", logo:<AssignmentIcon/>}, 
    {name:'Reportes', sidebar:"Reportes", logo: <FormatListBulletedIcon />}, 
    {name:'Reportes Generales', sidebar:"Reportes", logo:<PlaylistAddCheckIcon />},
];

const justificacionesOpciones = [
    {name:'Justificaciones', sidebar:"Justificaciones", logo: <ReportIcon />},
    {name:'Justificaciones Personales', sidebar:"Justificaciones", logo: <PersonIcon />},
    {name:'Justificaciones Generales', sidebar:"Justificaciones", logo: <GroupIcon />}, 
];

const justificacionesOpcionesPriv = [
    {name:'Justificaciones', sidebar:"Justificaciones", logo: <ReportIcon />},
    {name:'Justificaciones Personales', sidebar:"Justificaciones", logo: <PersonIcon />},
    {name:'Justificaciones Generales', sidebar:"Justificaciones", logo: <GroupIcon />}, 
    {name:'Administrar Justificaciones', sidebar:"Justificaciones", logo: <ChecklistRtlIcon />}, 
];

const horariosOpciones = [
    {name: 'Horarios', logo:<AccessAlarmIcon sx={{color: 'primary.light'}}/>},
    {name: 'Mi Horario', logo:<AlarmOnIcon sx={{color: 'primary.light'}}/>},
    {name: 'Horarios de Personal', logo:<AccessTimeIcon sx={{color: 'primary.light'}}/>},
];

const adminOpciones = [
    {name: 'Administrador', sidebar:"Administrador", logo:<AdminPanelSettingsIcon />},
    {name: 'Buscar Usuario', sidebar:"Administrador", logo:<PersonSearchIcon />},
    {name: 'Crear Usuario', sidebar:"Administrador", logo:<PersonAddAlt1Icon />},
    {name: 'Organigrama', sidebar:"Administrador", logo:<SchemaIcon />},
    {name: 'Buscar Lugar', sidebar:"Administrador", logo:<SearchIcon />},
];

const adminOpcionesSuper = [
    {name: 'Administrador', sidebar:"Administrador", logo:<AdminPanelSettingsIcon />},
    {name: 'Buscar Usuario', sidebar:"Administrador", logo:<PersonSearchIcon />},
    {name: 'Crear Usuario', sidebar:"Administrador", logo:<PersonAddAlt1Icon />},
    {name: 'Organigrama', sidebar:"Administrador", logo:<SchemaIcon />},
    {name: 'Buscar Lugar', sidebar:"Administrador", logo:<SearchIcon />},
    {name: 'Crear Lugar', sidebar:"Administrador", logo:<ApartmentIcon />},
];

const consultaOpciones = [
    {name: 'Búsqueda', sidebar:"Búsqueda", logo:<SearchIcon />},
    {name: 'Buscar Usuario', sidebar:"Administrador", logo:<PersonSearchIcon />},
    {name: 'Buscar Lugar', sidebar:"Administrador", logo:<FindInPageIcon />},
    {name: 'Organigrama', sidebar:"Administrador", logo:<SchemaIcon />}
];

export { pages, reportesOpciones, justificacionesOpciones, justificacionesOpcionesPriv, adminOpciones, adminOpcionesSuper, horariosOpciones, consultaOpciones };