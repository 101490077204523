import axios from "axios";

import { crear, buscar, buscar_hijas, buscar_id, editar, padre, restaurar, restaurar_mensajes, buscarCabeceras, ordenar_organigrama,buscar_solo_hijas, restaurar_hijas } from '../reducers/dependenciaReducer';

export const crearDependencia = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/dependency/crear`, data)
      .then(response => {
        dispatch(crear({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const buscarDependencia = (dependencia) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/dependencies/name`, dependencia)
      .then(response => {
        dispatch(buscar({ dependencias: response.data.dependencias }));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_dependencias = (dependencias) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/dependencies`, dependencias)
      .then(response => {
        dispatch(buscar({ dependencias: response.data.dependencias }));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_cabeceras = (cabeceras) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/cabeceras`, cabeceras)
      .then(response => {
        dispatch(buscarCabeceras({ cabeceras: response.data.cabeceras }));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_dependencias_hijas = (id) => { 
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/dependency/${id}/hijos`)
      .then(response => {
        dispatch(buscar_solo_hijas({ dependencias: response.data.dependencias }));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_dependencias_hijas_con_padre = (id) => { 
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/dependency/${id}/hijos-padre`)
      .then(response => {
        dispatch(buscar_hijas({ dependencias: response.data.dependencias, padre: response.data.padre }));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_por_id = (id) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/dependency/${id}`)
      .then(response => {
        dispatch(buscar_id({data: response.data, status: response.status, text: response.statusText}));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_padre = (id) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/dependency-padre/${id}`)
      .then(response => {
        dispatch(padre({data: response.data}));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_cabecera_padre = (id) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/cabecera-padre/${id}`)
      .then(response => {
        dispatch(padre({data: response.data}));
      })
      .catch(error => console.log(error));
  };
};

export const editarDependencia = (data) => {
  return (dispatch) => {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/dependency/editar`, data)
      .then(response => {
        dispatch(editar({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const getOrganigrama = () => {
  return (dispatch) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/organigrama`)
      .then(response => {
        dispatch(ordenar_organigrama({data: response.data}));
      })
      .catch(error => console.log(error));
  };
};

export const restaurarHijas = () => {
  return function(dispatch) {
    dispatch(restaurar_hijas());
  };
};

export const restaurarEstado = () => {
  return function(dispatch) {
    dispatch(restaurar());
  };
};

export const restaurarMensajes = () => {
  return function(dispatch) {
    dispatch(restaurar_mensajes());
  };
};

