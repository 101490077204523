import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';

// Actions
import { obtener_por_id } from '../../redux/action-types/estadisticasActions';

// Swiper 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

// MUI Componentes
import { 
  Box, 
  Button, 
  CircularProgress,
  Divider, 
  Grid, 
  Paper,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography, 
} from '@mui/material';

// Recharts
import { 
  PieChart,
  Pie as Torta,
  Cell, 
  Tooltip, 
  Legend, 
  ResponsiveContainer, 
} from 'recharts';

const COLORS = ['#005f61', '#f24c4c'];

const CustomTooltipPie = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    return (
      <Paper sx={{ p: 1 }}>
        <Typography variant="body2"><strong>{name}</strong></Typography>
        <Typography variant="body2" color="primary">
          Cantidad: {value.toFixed(2)}
        </Typography>
      </Paper>
    );
  };
  return null;
};

const DetalleEstadistica = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { estadistica } = useSelector(state => state.estadisticas);

  const handleAtras = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    dispatch(obtener_por_id(id));
  }, [dispatch, id]);

  if(!estadistica) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Estadísticas"} />
        <Grid container spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0.5vh",
          }}
        >
          {/* Botonera */}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button variant="contained"
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleAtras}
              >
                Dashboard
              </Button>
            </Box>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          {/* Progress */}
          <Grid>
            <CircularProgress 
              sx={{
                width: "auto",
                height: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />  
          </Grid>
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  const data = [
    { name: 'Presentes', value: estadistica?.empleados_presentes },
    { name: 'Ausentes', value: estadistica?.empleados_ausentes }
  ];

  const data2 = [
    { name: 'Presentes', value: estadistica?.empleados_presentes_promedio  * 100 / estadistica?.usuarios_vigentes },
    { name: 'Ausentes', value: (estadistica?.usuarios_vigentes - estadistica?.empleados_presentes_promedio ) * 100 / estadistica?.usuarios_vigentes }
  ];

  return (  
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={`Estadísticas de ${estadistica.nombre_dependencia}`} />
      <Grid container spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.5vh",
        }}
      >
        <Grid item container xs={12} sm={12} md={12} lg={12}
          sx={{
            display: "flex",
            height: "auto",
            justifyContent: "center",
          }}
        >
          {/* Botonera */}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button variant="contained"
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleAtras}
              >
                Dashboard
              </Button>
            </Box>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          
          {/*Usuarios*/}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          > 
            <Typography variant="h6" mb={2}>
              Información General
            </Typography>
            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column", marginBottom:"2em"}}>
              <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                <Box sx={{width: "47%", display: "flex", flexDirection:"column",flexGrow: 1, height: "100%"}}>
                  <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
                    <Typography >
                    Usuarios Totales:
                    </Typography>     
                    <Typography sx={{ml: "auto", marginRight: "3em"}}>
                      {estadistica?.cantidad_usuarios}
                    </Typography>   
                  </Box>       
                </Box>
                <Box sx={{width: "47%", display: "flex", flexDirection:"column",flexGrow: 1, height: "100%"}}>
                  <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
                    <Typography >
                      Designaciones Activas: 
                    </Typography>     
                    <Typography sx={{ml: "auto", marginRight: "3em"}}>
                      {estadistica?.usuarios_vigentes}
                    </Typography>   
                  </Box>  
                  <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
                    <Typography mr={"1em"}>
                      Designaciones Históricas: 
                    </Typography>     
                    <Typography sx={{ml: "auto", marginRight: "3em"}}>
                      {estadistica?.usuarios_historicos}
                    </Typography>   
                  </Box>  
                  <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
                    <Typography mr={"1em"}>
                      Totales:  
                    </Typography>     
                    <Typography sx={{ml: "auto", marginRight: "3em"}}>
                      {estadistica?.usuarios_vigentes + estadistica?.usuarios_historicos}
                    </Typography>   
                  </Box> 
                </Box>
              </Box>
            </Box>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em", mt: "1em" }}/> 
          </Grid>

          {/*Tasa Asistencia*/}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              height:'55vh'
            }}
          >
            { 
              estadistica?.empleados_presentes !== 0 ?
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  style={{
                    "--swiper-navigation-color": 'rgb(0, 95, 97)',
                  }}
                > 
                  <SwiperSlide key={1}>
                    <Grid item container xs={11} sm={10} md={10} lg={6.1}
                      key={estadistica.id}
                      sx={{
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        mb: "1vh",
                      }}
                    > 
                      <Typography variant="h6">
                        Tasa de Asistencia Diaria
                      </Typography>
                      <ResponsiveContainer width="100%" height="80%">
                        <PieChart>
                          <Torta
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(2)}%`}
                          >
                            {data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Torta>
                          <Tooltip content={<CustomTooltipPie />} />
                          <Legend />
                        </PieChart>
                        </ResponsiveContainer>
                      </Grid>
                  </SwiperSlide>
                    <SwiperSlide key={2}>
                      <Grid item container xs={11} sm={10} md={10} lg={6.1}
                        key={estadistica.id}
                        sx={{
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                          mb: "1vh",
                        }}
                      > 
                        <Typography variant="h6">
                          Tasa de Asistencia Mensual
                        </Typography>
                        <ResponsiveContainer width="100%" height="80%">
                          <PieChart>
                            <Torta
                              data={data2}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={120}
                              fill="#8884d8"
                              dataKey="value"
                              label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(2)}%`}
                            >
                              {data2.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Torta>
                            <Tooltip content={<CustomTooltipPie />} />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      </Grid>
                    </SwiperSlide>
                  </Swiper>
              :
                <Grid item container xs={11} sm={10} md={10} lg={6.1}
                  key={estadistica.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "100%",
                    height: 'auto',
                    alignItems: 'center',
                    bgcolor: 'red',
                  }}
                > 
                  <Typography variant="h6">
                    Tasa de Asistencia
                  </Typography>
                  <Grid marginTop={2}>
                    Sin que mostrar...
                  </Grid>
                </Grid>
            }
            <Divider sx={{ width: "100%", height: "1em", mb: "1em", mt: "1em" }}/> 
          </Grid>
          
          {/* { 
            estadistica?.empleados_presentes !== 0 ?
              <Grid item container xs={11} sm={10} md={10} lg={6.1}
                key={estadistica.id}
                sx={{
                  display: 'flex',
                  height: 'auto',
                  justifyContent: 'center',
                  mb: "1vh",
                }}
              > 
                <Typography variant="h6">
                  Tasa de Asistencia
                </Typography>
                <ResponsiveContainer width="100%" height="80%">
                  <PieChart>
                    <Torta
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={120}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Torta>
                    <Tooltip content={<CustomTooltipPie />} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
                <Divider sx={{ width: "100%", height: "1em", mb: "1em", mt: "1em" }}/> 
              </Grid>
            :
              <Grid item container xs={11} sm={10} md={10} lg={6.1}
                key={estadistica.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'auto',
                  alignItems: 'center',
                  mb: "1vh",
                }}
              > 
                <Typography variant="h6">
                  Tasa de Asistencia
                </Typography>
                <Grid marginTop={2}>
                  Sin que mostrar...
                </Grid>
                <Divider sx={{ width: "100%", height: "1em", mb: "1em", mt: "1em" }}/> 
              </Grid>
          } */}
          
          {/*Marcaciones*/}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          > 
            <Typography variant="h6" mb={2}>
              Marcaciones
            </Typography>
            <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
              <Table sx={{ minWidth: 300 }} aria-label="tabla de horas pico">
                <TableHead>
                  <TableRow sx={{bgcolor:"primary.light"}}>
                    <TableCell align="center">Del Día</TableCell>
                    <TableCell align="center">Promedio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center"> {estadistica.cantidad_marcaciones ? estadistica?.cantidad_marcaciones : 'N/A'}</TableCell>
                    <TableCell align="center"> {estadistica.cantidad_marcaciones_promedio ? estadistica?.cantidad_marcaciones_promedio.toFixed(2)  : 'N/A'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em", mt: "1em" }}/> 
          </Grid>

          {/*Horas Pico*/}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          > 
            <Typography variant="h6" mb={2}>
              Horarios Pico Entrada/Salida:
            </Typography>
            <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
              <Table sx={{ minWidth: 300 }} aria-label="tabla de horas pico">
                <TableHead>
                  <TableRow sx={{bgcolor:"primary.light"}}>
                    <TableCell>Horario</TableCell>
                    <TableCell align="center">Del Día</TableCell>
                    <TableCell align="center">Promedio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Entrada</TableCell>
                    <TableCell align="center">
                      {estadistica.hora_pico_entrada ? new Date(estadistica?.hora_pico_entrada).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      {estadistica.hora_pico_entrada_promedio ? estadistica?.hora_pico_entrada_promedio?.slice(0, 5) : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Salida</TableCell>
                    <TableCell align="center">
                      {estadistica.hora_pico_salida ? new Date(estadistica?.hora_pico_salida).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      {estadistica.hora_pico_salida_promedio ? estadistica?.hora_pico_salida_promedio?.slice(0, 5) : 'N/A'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      {/*Pie de Pag.*/}
      <Pie />
    </>
  );
};

export default DetalleEstadistica;