import axios from "axios";

import { buscar_todas, buscar_id } from '../reducers/estadisticasReducer';

export const obtener_estadisticas = () => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/estadisticas`)
      .then(response => {
        dispatch(buscar_todas(response.data));
      })
      .catch(error => console.log(error));
  };
};

export const obtener_por_id = (id) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/estadistica/${id}`)
      .then(response => {
        dispatch(buscar_id(response.data));
      })
      .catch(error => console.log(error));
  };
};