import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Handle, Position } from '@xyflow/react';

// MUI componentes
import { 
  Box,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

const Dependencia = ({ data, isConnectable }) => {
    const { label, cabecera } = data

  const titulo = label.length > 70 ? label.split(' ').splice(0, 5).join(' ') + '(...)' : label;

  return (
    <Box maxWidth="full" component="div"
      sx={{
        width: cabecera ? '30em' : "15em",
        height: cabecera ? '7em' : "5em",
        minHeight: '5em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '.5em',
        borderRadius: '.5em',
        padding: ".5em",
        boxShadow: cabecera ? "1px 1px 3px 3px rgba(40,40,40,0.2)" :'1px 1px 3px 3px rgba(40,40,40,0.4)',
        bgcolor: cabecera ? 'primary.light' : 'background.paper',
      }}
    > 
    <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      {
        cabecera ?
        <ApartmentIcon sx={{ fontSize:"4.5em", color: 'background.paper'  }} />
        :
        <HomeWorkIcon sx={{ fontSize:"2.5em", color: 'primary.light'  }} />
      }     
        
      
      <Box component="div" 
        sx={{
          width: "100%",
          display: "flex",
          flexDirection:"column",
          marginLeft: ".5em"
        }}
      >
        <Box component="div" >
          <Typography variant="p" sx={{fontWeight: cabecera ? 'bold' : "", fontSize: cabecera ? "1.2em" : "0.9em", color: cabecera ? 'background.paper' : 'black'}} >
            {titulo}
          </Typography>
        </Box>
      </Box>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </Box>
  );
};

export default Dependencia;